import { SearchPlaceResolvers } from '../../__codegen__/__graphql__/resolvers'
import { V1DistanceUnit } from '../../__codegen__/__openapi__/search-server/place/v4/place'

export const SearchPlace: SearchPlaceResolvers = {
  id(parent) {
    return `SearchPlace#${parent.meta.docId}`
  },
  _id(parent) {
    return parent.source.id
  },
  createdAt(parent) {
    if (!parent.source?.createdAt) {
      return
    }
    return new Date(parent.source.createdAt).toISOString()
  },
  description(parent) {
    return parent.source.description ?? ''
  },
  regionName(parent) {
    return parent.fetch.regionName ?? ''
  },
  categoryId(parent) {
    return parent.source.categoryId ?? ''
  },
  categoryText(parent) {
    return parent.source.categoryText ?? ''
  },
  displayCategoryName(parent) {
    return parent.source.displayCategoryName ?? ''
  },
  name(parent) {
    return parent.source.name
  },
  collectionName(parent) {
    return parent.meta.collectionName ?? ''
  },
  location(parent) {
    return parent.source.location ?? ''
  },
  address(parent) {
    return parent.source.address ?? ''
  },
  jibunAddress(parent) {
    return parent.source.jibunAddress ?? ''
  },
  reviewCount(parent) {
    return parent.source.totalReviewCount ?? 0
  },
  followsCount(parent) {
    return parent.source.followsCount ?? 0
  },
  storeOnSale(parent) {
    return parent.source.storeOnSale ?? false
  },
  reservable(parent) {
    return parent.source.reservable ?? false
  },
  hasCoupon(parent) {
    return parent.fetch.hasCoupon ?? false
  },
  distance(parent) {
    if (!parent.source.distance?.isValid) {
      return null
    }

    if (
      !parent.source.distance ||
      !parent.source.distance.unit ||
      !parent.source.distance.value
    ) {
      return null
    }

    return {
      unit: distanceEnumMapper(parent.source.distance.unit),
      value: parent.source.distance.value ?? 0,
    }
  },
  themeIds(parent) {
    return parent.source.themeIds ?? []
  },
  picture(parent) {
    return parent.fetch.picture
      ? {
          url: parent.fetch.picture.url,
          thumbnail: parent.fetch.picture.thumbnail,
          medium: parent.fetch.picture.medium,
        }
      : null
  },
  bgImage(parent) {
    return parent.fetch.bgImage
      ? {
          url: parent.fetch.bgImage.url,
          thumbnail: parent.fetch.bgImage.thumbnail,
          medium: parent.fetch.bgImage.medium,
        }
      : null
  },
}

const distanceEnumMapper = (distanceUnit: V1DistanceUnit) => {
  switch (distanceUnit) {
    case V1DistanceUnit.METER:
      return 'METER'
    case V1DistanceUnit.KILOMETER:
      return 'KILOMETER'
    case V1DistanceUnit.UNSPECIFIED:
    default:
      return 'UNKNOWN'
  }
}
