import { JobPostResolvers } from '../../__codegen__/__graphql__/resolvers'
import {
  V1JobSalaryType,
  V1JobWorkDay,
  V1JobWorkPeriod,
} from '../../__codegen__/__openapi__/search-server/integratesearch/v4/integratesearch'
import { WORK_DAYS } from '../constants'

export const JobPost: JobPostResolvers = {
  id(parent) {
    return `JobPost#${parent.id}`
  },
  _id(parent) {
    return parent.id
  },
  image(parent) {
    if (!parent.images?.length || !parent.images[0].thumbnail) {
      return null
    }
    return {
      thumbnail: parent.images[0].thumbnail,
    }
  },
  regionName(parent) {
    return parent.regionName ?? ''
  },
  targetUri(parent) {
    return parent.targetUri ?? ''
  },
  workDays(parent) {
    if (!parent.workDays) {
      return []
    }
    const workDays = parent.workDays.map((workDay) => {
      switch (workDay) {
        case V1JobWorkDay.MON: {
          return WORK_DAYS.MON.ID
        }
        case V1JobWorkDay.TUE: {
          return WORK_DAYS.TUE.ID
        }
        case V1JobWorkDay.WED: {
          return WORK_DAYS.WED.ID
        }
        case V1JobWorkDay.THU: {
          return WORK_DAYS.THU.ID
        }
        case V1JobWorkDay.FRI: {
          return WORK_DAYS.FRI.ID
        }
        case V1JobWorkDay.SAT: {
          return WORK_DAYS.SAT.ID
        }
        case V1JobWorkDay.SUN: {
          return WORK_DAYS.SUN.ID
        }
        case V1JobWorkDay.UNSPECIFIED:
        default: {
          return WORK_DAYS.UNKNOWN.ID
        }
      }
    })
    return workDays
  },
  authorBadges(parent) {
    if (!parent.authorBadges) {
      return []
    }
    return parent.authorBadges
  },
  salaryType(parent) {
    switch (parent.salaryType) {
      case V1JobSalaryType.MONTHLY: {
        return 'MONTHLY'
      }
      case V1JobSalaryType.DAILY: {
        return 'DAILY'
      }
      case V1JobSalaryType.HOURLY: {
        return 'HOURLY'
      }
      case V1JobSalaryType.PER_CASE: {
        return 'PER_CASE'
      }
      case V1JobSalaryType.UNSPECIFIED:
      default: {
        return 'UNKNOWN'
      }
    }
  },
  workPeriod(parent) {
    switch (parent.workPeriod) {
      case V1JobWorkPeriod.LESS_THAN_A_MONTH: {
        return 'LESS_THAN_A_MONTH'
      }
      case V1JobWorkPeriod.MORE_THAN_A_MONTH: {
        return 'MORE_THAN_A_MONTH'
      }
      case V1JobWorkPeriod.UNSPECIFIED:
      default: {
        return 'UNKNOWN'
      }
    }
  },
  lastBringUpDate(parent) {
    if (!parent.lastBringUpDate) {
      return
    }
    return new Date(parent.lastBringUpDate).toISOString()
  },
  publishedAt(parent) {
    if (!parent.publishedAt) {
      return
    }
    return new Date(parent.publishedAt).toISOString()
  },
  cursor(parent) {
    return parent.cursor ?? ''
  },
  index(parent) {
    return parent.index ?? 0
  },
}
