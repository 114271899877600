import { QueryResolvers } from '../../__codegen__/__graphql__/resolvers'

export const FleamarketRelatedKeywordsQueries: QueryResolvers = {
  async fleaMarketRelatedKeywords(parent, args, ctx) {
    const resp = await ctx.services.searchV2.getFleaMarketRelatedKeywords({
      regionId: args.data.regionId,
      keyword: args.data.keyword,
      referrer: {
        queryId: args.referrer.queryId,
        queryFromId: args.referrer.queryFromId,
        funnelFromId: args.referrer.funnelFromId,
        tab: args.referrer.tab,
        screenDepthName: args.referrer.screenDepthName,
        searchFunnelId: args.referrer.searchFunnelId,
        experiment: ctx.experiment,
      },
    })

    if (!resp) {
      return []
    }

    return resp.keywords ?? []
  },
}
