import { QueryResolvers } from '../../__codegen__/__graphql__/resolvers'
const FEED_AUTO_COMPLETE_PAGE_SIZE = 15
export const FeedAutoCompleteQueries: QueryResolvers = {
  async feedAutoComplete(parent, args, ctx) {
    const resp = await ctx.services.autoComplete.getFeedAutoComplete({
      regionId: args.data.regionId.toString(),
      prefix: args.data.prefix,
      pageSize: FEED_AUTO_COMPLETE_PAGE_SIZE,
      referrer: {
        funnelFromId: args.referrer.funnelFromId,
        experiment: ctx.experiment,
      },
    })

    if (!resp) {
      throw new Error('Feed AutoComplete를 불러오는데 실패했습니다.')
    }

    return resp
  },
}
