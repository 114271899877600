import { Resolvers } from '../../__codegen__/__graphql__/resolvers'

export const FleaMarketUserKeywordMutations: Resolvers['Mutation'] = {
  /**
   * 키워드 알림 등록하기
   */
  async createFleaMarketUserKeyword(parent, args, ctx) {
    const result = await ctx.services.karrotMarket.createUserKeyword({
      keyword: args.input?.keyword,
    })

    if (!result) {
      throw new Error()
    }

    return result
  },
}

export const CreateFleaMarketUserKeywordOutput: Resolvers['CreateFleaMarketUserKeywordOutput'] =
  {
    __resolveType(parent) {
      if ('userKeyword' in parent) {
        return 'CreateFleaMarketUserKeywordOutputResult'
      } else {
        return 'CreateFleaMarketUserKeywordOutputError'
      }
    },
  }
