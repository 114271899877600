import { take } from 'lodash-es'
import {
  InputMaybe,
  MapCoordinatesInput,
  PlaceLocationInput,
  QueryResolvers,
  SearchPlaceFilterInputSort,
} from '../../__codegen__/__graphql__/resolvers'
import { V4SearchSort as V4SearchPlaceSort } from '../../__codegen__/__openapi__/search-server/place/v4/place'
import { PLACE_FILTER_SORTS } from '../stores/placeFilterSortStore'

export const SearchPlaceQueries: QueryResolvers = {
  /**
   * 동네업체 (POI + 비즈계정) 검색 결과
   */
  async searchPlaces(parent, args, ctx) {
    const first = args.first + 1
    const resp = await ctx.services.place.getPlaces({
      query: args.data.query,
      regionId: args.data.regionId.toString(),
      useLocation: args.data.useLocation,
      sort: convertSortToPlaceSort(args.data.sort ?? undefined),
      location: convertLocationToLocationParam(args.data.location ?? undefined),
      pageSize: first,
      pageToken: args.after ?? undefined,
      mapLocation: convertMapCoordinatesToMapLocationParam(
        args.data.mapCoordinates ?? undefined
      ),
      useMap: !!args.data.mapCoordinates,
      referrer: {
        queryId: args.referrer.queryId,
        queryFromId: args.referrer.queryFromId,
        funnelFromId: args.referrer.funnelFromId,
        tab: args.referrer.tab,
        screenDepthName: args.referrer.screenDepthName,
        searchFunnelId: args.referrer.searchFunnelId,
        experiment: ctx.experiment,
      },
    })

    if (!resp) {
      throw new Error('Failed to places search result')
    }

    return {
      edges: take(resp.connection.edges, args.first),
      pageInfo: {
        hasNextPage: resp.connection.edges.length > args.first,
        hasPreviousPage: false,
        startCursor: resp.connection.edges[0]?.cursor ?? null,
        endCursor: resp.connection.edges[args.first - 1]?.cursor ?? null,
      },
      experiment: resp.experiment,
      error: resp.error,
    }
  },
}

const convertSortToPlaceSort = (
  sort: InputMaybe<SearchPlaceFilterInputSort> | undefined
) => {
  switch (sort) {
    case PLACE_FILTER_SORTS.DISTANCE.ID:
      return V4SearchPlaceSort.DISTANCE
    case PLACE_FILTER_SORTS.RELEVANT.ID:
    default:
      return V4SearchPlaceSort.RELEVANT
  }
}
export const convertLocationToLocationParam = (
  location?: InputMaybe<PlaceLocationInput>
) => {
  if (!location) {
    return undefined
  }
  return {
    lat: location.latitude,
    lon: location.longitude,
  }
}

const convertMapCoordinatesToMapLocationParam = (
  mapCoordinates?: InputMaybe<MapCoordinatesInput>
) => {
  if (!mapCoordinates) {
    return undefined
  }
  const center = convertLocationToLocationParam(mapCoordinates.center)
  const topLeft = convertLocationToLocationParam(mapCoordinates.northWest)
  const bottomRight = convertLocationToLocationParam(mapCoordinates.southEast)

  if (!center || !topLeft || !bottomRight) {
    return undefined
  }
  return {
    center,
    topLeft,
    bottomRight,
  }
}
