import { QueryResolvers } from '../../__codegen__/__graphql__/resolvers'
import { compact } from 'lodash-es'
import * as R from 'ramda'

export const UserKeywordQueries: QueryResolvers = {
  /**
   * 중고거래 키워드 알림
   */
  async userKeyword(parent, args, ctx) {
    const resp = await ctx.services.userKeyword.getUserKeyword({
      query: args.data.query,
      referrer: { experiment: ctx.experiment },
    })

    if (!resp) {
      throw new Error('User Keyword를 불러오는데 실패했습니다.')
    }

    return resp
  },
  async fleaMarketCategories(parent, args, ctx) {
    return R.pipe(
      () =>
        ctx.services.category.getFleaMarketCategoryList({
          referrer: { experiment: ctx.experiment },
        }),
      R.andThen((resp) => resp?.categories ?? []),
      R.andThen(compact)
    )()
  },
  async fleaMarketFilterRecommend(parent, args, ctx) {
    const resp = await ctx.services.userKeyword.getFleaMarketFilterRecommend({
      query: args.data.query,
      regionId: args.data.regionId,
      referrer: { experiment: ctx.experiment },
    })

    if (!resp) {
      console.error('FleaMarket FilterRecommend을 불러오는데 실패했습니다.')
      return {}
    }

    return resp
  },
}
