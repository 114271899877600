import { Resolvers } from '../../__codegen__/__graphql__/resolvers'
import { V4CategoryKind } from '../../__codegen__/__openapi__/search-server/category/v4/category'

export const Category: Resolvers['Category'] = {
  id(parent) {
    return `Category#${parent.id}`
  },
  _id(parent) {
    return Number(parent.id)
  },
  iconImage(parent) {
    return parent.iconImage ?? ''
  },
  kind(parent) {
    switch (parent.kind) {
      case V4CategoryKind.FLEAMARKET:
        return 'FLEA_MARKET'
      case V4CategoryKind.BUSINESS:
        return 'BUSINESS'
      case V4CategoryKind.COMMUNITY:
        return 'COMMUNITY'
      case V4CategoryKind.ALL:
        return 'ALL'
      case V4CategoryKind.UNSPECIFIED:
      default:
        return 'UNKNOWN'
    }
  },
}
