import { Resolvers } from '../../__codegen__/__graphql__/resolvers'

export const SearchBizPost: Resolvers['SearchBizPost'] = {
  id(parent) {
    return `SearchBizPost#${parent.docId}`
  },
  _id(parent) {
    return parent.docId ?? ''
  },
  index(parent) {
    return parent.index ?? 0
  },
  userType(parent) {
    return parent.userType ?? ''
  },
  createdAt(parent) {
    if (!parent.createdAt) {
      return
    }

    return new Date(parent.createdAt).toISOString()
  },
  title(parent) {
    return parent.title ?? ''
  },
  hasCoupon(parent) {
    return parent.hasCoupon ?? false
  },
  categoryId(parent) {
    return parent.categoryId ?? ''
  },
  regionName(parent) {
    return parent.regionName ?? ''
  },
  bookmarkCount(parent) {
    return parent.bookmarkCount ?? 0
  },
}
