import { getServiceHoian, ServiceHoian } from '../../services/hoian'
import { getServiceSearchV2, ServiceSearchV2 } from '../../services/search_v2'
import {
  getServiceAggregatedPreProcess,
  getServiceSearchAggregated,
  ServiceAggregatedPreProcessType,
  ServiceSearchAggregatedType,
} from '../../aggregated/services'
import { getServiceXProperty, ServiceXProperty } from '../../services/xProperty'
import {
  UserKeywordService,
  getUserKeywordService,
} from '../../flea-market/services'
import { AppType } from '../context/FoundationProvider'
import {
  getServiceSearchCommunity,
  ServiceSearchCommunityType,
} from '../../community/services'
import { SearchExperimentReferrerType } from '../../referrer/types'
import {
  getServiceKarrotMarket,
  ServiceKarrotMarket,
} from '../../flea-market/services/ServiceKarrotMarket'
import { getServiceUser, ServiceUserType } from '../../user/services'
import {
  getServiceSearchBizPost,
  ServiceSearchBizPostType,
} from '../../business/services'
import { getServiceCategory, ServiceCategory } from '../../category/services'
import {
  getServiceAutoComplete,
  ServiceAutoCompleteType,
} from '../../auto-complete/services'
import {
  getServiceSearchFleaMarket,
  ServiceSearchFleaMarketType,
} from '../../flea-market/services/ServiceSearchFleaMarket'
import {
  getServiceRecentSearch,
  ServiceRecentSearchType,
} from '../../recent-search/services'
import {
  getServiceSearchPlace,
  ServiceSearchPlaceType,
} from '../../place/services/ServiceSearchPlace'

export interface MyContext {
  app: AppType
  authToken: string
  experiment: SearchExperimentReferrerType
  regionId?: number
  services: {
    searchV2: ServiceSearchV2
    userKeyword: UserKeywordService
    karrotMarket: ServiceKarrotMarket
    hoian: ServiceHoian
    xProperty: ServiceXProperty
    autoComplete: ServiceAutoCompleteType
    category: ServiceCategory
    aggregated: ServiceSearchAggregatedType
    aggregatedPreProcess: ServiceAggregatedPreProcessType
    fleaMarket: ServiceSearchFleaMarketType
    community: ServiceSearchCommunityType
    user: ServiceUserType
    bizPost: ServiceSearchBizPostType
    recentSearch: ServiceRecentSearchType
    place: ServiceSearchPlaceType
  }
}

export function createContext({
  app,
  authToken,
  regionId,
  experiment,
}: {
  app: AppType
  authToken: string
  regionId?: number
  experiment: SearchExperimentReferrerType
}): MyContext {
  return {
    app,
    authToken,
    regionId,
    experiment,
    services: {
      searchV2: getServiceSearchV2({
        app,
        authToken,
      }),
      userKeyword: getUserKeywordService({
        app,
        authToken,
      }),
      hoian: getServiceHoian({ app, authToken }),
      karrotMarket: getServiceKarrotMarket({ app, authToken }),
      xProperty: getServiceXProperty({ app, authToken }),
      autoComplete: getServiceAutoComplete({ app, authToken }),
      category: getServiceCategory({ app, authToken }),
      aggregated: getServiceSearchAggregated({
        app,
        authToken,
      }),
      aggregatedPreProcess: getServiceAggregatedPreProcess({
        app,
        authToken,
      }),
      fleaMarket: getServiceSearchFleaMarket({ app, authToken }),
      community: getServiceSearchCommunity({
        app,
        authToken,
      }),
      user: getServiceUser({ app, authToken }),
      bizPost: getServiceSearchBizPost({ app, authToken }),
      recentSearch: getServiceRecentSearch({ app, authToken }),
      place: getServiceSearchPlace({ app, authToken }),
    },
  }
}
