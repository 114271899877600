import { SearchPlaceCreateBannerResolvers } from '../../__codegen__/__graphql__/resolvers'

export const SearchPlaceCreateBanner: SearchPlaceCreateBannerResolvers = {
  id(parent) {
    return `SearchPlaceCreateBanner#${parent.id}`
  },
  _id(parent) {
    return parent.id
  },
  content(parent) {
    return parent.content ?? ''
  },
}
