import { graphql } from 'graphql'

import { makeExecutableSchema } from '@graphql-tools/schema'

import { typeDefs } from '../../../__codegen__/__graphql__/typeDefs'
import { MyContext } from '../context'
import { resolvers } from './resolvers'

export function createSchema() {
  const schema = makeExecutableSchema({
    typeDefs,
    resolvers: resolvers as any,
  })

  return {
    schema,
    query({
      query,
      variables,
      context,
    }: {
      query: string
      variables?: Record<string, any>
      context: MyContext
    }): Promise<any> {
      return graphql({
        schema: schema!,
        source: query,
        variableValues: variables,
        contextValue: context,
      })
    },
  }
}
