import {
  RelatedKeywordResolvers,
  RelatedKeywordsResolvers,
} from '../../__codegen__/__graphql__/resolvers'
import {
  V1KeywordType,
  V1RelatedKeywordsTemplate,
} from '../../__codegen__/__openapi__/search-server/fleamarket/v4/fleamarket'

export const RelatedKeywords: RelatedKeywordsResolvers = {
  keywords(parent) {
    return parent.keywords ?? []
  },
  template(parent) {
    switch (parent.template) {
      case V1RelatedKeywordsTemplate.LIST_VIEW:
        return 'LIST_VIEW'
      default:
        return 'UNKNOWN'
    }
  },
}

export const RelatedKeyword: RelatedKeywordResolvers = {
  type(parent) {
    switch (parent.type) {
      case V1KeywordType.KEYWORD:
        return 'KEYWORD'
      case V1KeywordType.SHORTCUT:
        return 'SHORTCUT'
      case V1KeywordType.UNSPECIFIED:
      default:
        return 'UNSPECIFIED'
    }
  },
}
