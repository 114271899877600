import { Resolvers } from '../../__codegen__/__graphql__/resolvers'

export const SearchCommerce: Resolvers['SearchCommerce'] = {
  id(parent) {
    return `SearchCommerce#${parent.docId}`
  },
  _id(parent) {
    return parent.docId
  },
  index(parent) {
    return parent.index ?? 0
  },
  product(parent) {
    return {
      name: parent.product?.name ?? '',
      isSoldOut: parent.product?.isSoldOut ?? false,
      thumbnail: parent.product?.thumbnail ?? '',
      price: {
        amount: parent.product?.price?.amount ?? 0,
        currency: parent.product?.price?.currency ?? '',
      },
      discountInfo: {
        amount: parent.product?.discountInfo?.amount ?? 0,
        currency: parent.product?.discountInfo?.currency ?? '',
        ratio: parent.product?.discountInfo?.discountRatio ?? 0,
      },
      displayPrice: {
        currency: parent.product?.displayPrice?.currency ?? '',
        amount: parent.product?.displayPrice?.amount ?? 0,
      },
      regionName: parent.product?.regionName ?? '',
    }
  },
  delivery(parent: any) {
    // proto file에서 복수형일 경우 자동으로 List를 붙임, json에서 내려오는 없는 단어도 처리하게 방어코드 추가
    if (parent.delivery?.flagList) {
      return { flag: parent.delivery?.flagList }
    }

    if (parent.delivery?.flag) {
      return { flag: parent.delivery?.flag }
    }

    return { flag: [] }
  },
  userReaction(parent) {
    return {
      reviewCount: parent.userReaction?.reviewCount ?? 0,
      watchCount: parent.userReaction?.watchCount ?? 0,
    }
  },
  store(parent) {
    return {
      name: parent?.store?.name ?? '',
    }
  },
}
