import {
  InputMaybe,
  QueryResolvers,
  SearchFleaMarketFilterInputSort,
  SearchFleaMarketsDataInputRegionRange,
} from '../../__codegen__/__graphql__/resolvers'
import { FLEA_MARKET_FILTER_SORT } from '../../flea-market-filter-sort/stores/fleaMarketFilterSortStore'
import {
  V1RegionRange as V1SearchFleaMarketRegionRange,
  V4SearchSort as V4SearchFleaMarketSort,
} from '../../__codegen__/__openapi__/search-server/fleamarket/v4/fleamarket'
import { REGION_RANGE } from '../../flea-market-filter-region/stores/fleaMarketFilterRegionStore'

export const SearchFleaMarketQueries: QueryResolvers = {
  /**
   * 중고거래 검색 결과
   */
  async searchFleaMarkets(parent, args, ctx) {
    const resp = await ctx.services.fleaMarket.getFleaMarkets({
      query: args.data.query,
      regionId: args.data.regionId.toString(),
      withoutCompleted: args.data.hideClosedArticles ?? false,
      categoryIds: args.data.categoryIds ?? [],
      sort: convertSortToFleaMarketSort(args.data.sort ?? undefined),
      priceGte: args.data.priceGte ?? undefined,
      priceLte: args.data.priceLte ?? undefined,
      regionRange: convertReginRangeToFleaMarketRegionRange(
        args.data.regionRange ?? undefined
      ),
      pageSize: args.first,
      pageToken: args.after ?? undefined,
      isFreeShare: getIsFreeShareParam({
        priceLte: args.data.priceLte,
        priceGte: args.data.priceGte,
      }),
      freeShareEventEnabled: args.data.freeShareEventEnabled,
      referrer: {
        queryId: args.referrer.queryId,
        queryFromId: args.referrer.queryFromId,
        funnelFromId: args.referrer.funnelFromId,
        tab: args.referrer.tab,
        screenDepthName: args.referrer.screenDepthName,
        searchFunnelId: args.referrer.searchFunnelId,
        experiment: ctx.experiment,
      },
    })

    if (!resp) {
      throw new Error('Failed to fleamarket search result')
    }

    const lastIndex = resp.connection.edges.length - 1
    const hasNextPage = resp.connection.edges.length >= args.first

    return {
      edges: resp.connection.edges,
      pageInfo: {
        hasNextPage: hasNextPage,
        hasPreviousPage: false,
        startCursor: resp.connection.edges[0]?.cursor ?? null,
        endCursor: resp.connection.edges[lastIndex]?.cursor ?? null,
      },
      experiment: resp.experiment,
      error: resp.error,
    }
  },
}
const getIsFreeShareParam = ({
  priceLte,
  priceGte,
}: {
  priceLte?: InputMaybe<number>
  priceGte?: InputMaybe<number>
}) => {
  return (
    (priceLte === 0 && priceGte === 0) || (priceLte === 0 && priceGte === null)
  )
}

const convertSortToFleaMarketSort = (
  sort: InputMaybe<SearchFleaMarketFilterInputSort> | undefined
) => {
  switch (sort) {
    case FLEA_MARKET_FILTER_SORT.RECENT.ID:
      return V4SearchFleaMarketSort.RECENT
    case FLEA_MARKET_FILTER_SORT.RELEVANT.ID:
    default:
      return V4SearchFleaMarketSort.RELEVANT
  }
}

const convertReginRangeToFleaMarketRegionRange = (
  regionRange: InputMaybe<SearchFleaMarketsDataInputRegionRange> | undefined
) => {
  switch (regionRange) {
    case REGION_RANGE.MY.ID:
      return V1SearchFleaMarketRegionRange.MY
    case REGION_RANGE.ADJACENT.ID:
      return V1SearchFleaMarketRegionRange.ADJACENT
    case REGION_RANGE.RANGE_2.ID:
      return V1SearchFleaMarketRegionRange.RANGE2
    case REGION_RANGE.RANGE_3.ID:
    default:
      return V1SearchFleaMarketRegionRange.RANGE3
  }
}
