import {
  CAR_OPTION_NAME,
  CAR_STATUS,
  CAR_HISTORY_CHANGE_TYPE,
} from '../constants'
import {
  SearchCarFilterResolvers,
  SearchCarResolvers,
} from '../../__codegen__/__graphql__/resolvers'
import {
  V1CarChangeHistoryType,
  V1CarDescription,
  V1CarOptionName,
  V1CarStatus,
} from '../../__codegen__/__openapi__/search-server/integratesearch/v4/integratesearch'

const carStatusEnumMapper = (carStatus?: V1CarStatus) => {
  if (!carStatus) return CAR_STATUS.NONE
  switch (carStatus) {
    case V1CarStatus.RESERVE: {
      return CAR_STATUS.RESERVE
    }
    case V1CarStatus.SALE: {
      return CAR_STATUS.SALES_STATUS
    }
    case V1CarStatus.TRADED: {
      return CAR_STATUS.TRADED
    }
    case V1CarStatus.UNSPECIFIED:
    default: {
      return CAR_STATUS.NONE
    }
  }
}

const carOptionNameEnumMapper = (carOptionName?: V1CarOptionName) => {
  if (!carOptionName) return CAR_OPTION_NAME.NOT_SPECIFIED
  switch (carOptionName) {
    case V1CarOptionName.SMOKING_FREE: {
      return CAR_OPTION_NAME.SMOKING_FREE
    }
    case V1CarOptionName.UNSPECIFIED:
    default: {
      return CAR_OPTION_NAME.NOT_SPECIFIED
    }
  }
}

const carHistoryChangeTypeEnumMapper = (
  carHistoryChange: V1CarChangeHistoryType
) => {
  if (!carHistoryChange) return CAR_HISTORY_CHANGE_TYPE.UNKNOWN
  switch (carHistoryChange) {
    case V1CarChangeHistoryType.REGISTER: {
      return CAR_HISTORY_CHANGE_TYPE.REGISTER
    }
    case V1CarChangeHistoryType.CAR_NO: {
      return CAR_HISTORY_CHANGE_TYPE.CAR_NO
    }
    case V1CarChangeHistoryType.OWNER: {
      return CAR_HISTORY_CHANGE_TYPE.OWNER
    }
    default: {
      return CAR_HISTORY_CHANGE_TYPE.UNKNOWN
    }
  }
}

export const SearchCar: SearchCarResolvers = {
  id(parent) {
    return `SearchCar#${parent.id}`
  },
  _id(parent) {
    return parent.id
  },
  index(parent) {
    return parent.index ?? 0
  },
  title(parent) {
    return parent.title ?? ''
  },
  price(parent) {
    return parent.price ?? 0
  },
  displayPrice(parent) {
    return parent.displayPrice ?? ''
  },
  content(parent) {
    return parent.content ?? ''
  },
  regionName(parent) {
    return parent.regionName ?? ''
  },
  carStatus(parent) {
    return carStatusEnumMapper(parent.carStatus)
  },
  carOptionNamesList(parent) {
    if (!parent.carOptionNames) return []

    const carOptionNamesListMapper = (list: V1CarOptionName[]) => {
      return list.map((item: V1CarOptionName) => carOptionNameEnumMapper(item))
    }

    return carOptionNamesListMapper(parent.carOptionNames)
  },
  chatCount(parent) {
    return parent.chatCount ?? 0
  },
  voteCount(parent) {
    return parent.voteCount ?? 0
  },
  firstImage(parent) {
    return parent.firstImage
      ? {
          thumbnail: parent.firstImage.thumbnail,
        }
      : null
  },
  pullUpCount(parent) {
    return parent.pullUpCount ?? 0
  },
  createdAt(parent) {
    if (!parent.createdAt) {
      return
    }
    return new Date(parent.createdAt).toISOString()
  },
  updatedAt(parent) {
    if (!parent.updatedAt) {
      return
    }
    return new Date(parent.updatedAt).toISOString()
  },
  publishedAt(parent) {
    if (!parent.publishedAt) {
      return
    }
    return new Date(parent.publishedAt).toISOString()
  },
  carData(parent) {
    const carDataMapper = (
      carData: V1CarDescription,
      carHistoryChangesList: V1CarChangeHistoryType[]
    ) => {
      return {
        driveDistance: carData.driveDistance ?? 0,
        modelYear: carData.modelYear ?? 0,
        carName: carData.carName ?? '',
        carHistoryChangesList: carHistoryChangesList.map(
          (item: V1CarChangeHistoryType) => carHistoryChangeTypeEnumMapper(item)
        ),
      }
    }

    if (!parent.carDescription) {
      return null
    }
    const carData = {
      driveDistance: parent.carDescription.driveDistance ?? 0,
      modelYear: parent.carDescription.modelYear ?? 0,
      carName: parent.carDescription.carName ?? '',
      carHistoryChangesList: [],
    }

    if (parent.carDescription.changeHistories) {
      return carDataMapper(carData, parent.carDescription.changeHistories)
    }
    return carData
  },
}

export const SearchCarFilter: SearchCarFilterResolvers = {
  companyIdsList(parent: any) {
    if (!parent.companyIds) {
      return []
    }
    return parent.companyIds
  },
  seriesIdsList(parent: any) {
    if (parent.seriesIds) {
      return []
    }
    return parent.seriesIds
  },
  subseriesIdsList(parent: any) {
    if (parent.subseriesIds) {
      return []
    }
    return parent.subseriesIds
  },
}
