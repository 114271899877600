import {
  OVERFLOW_ACTION,
  OVERFLOW_STYLE,
} from '../../_app/components/draggable-overflow-bottom-sheet/constants'
import {
  V1OverflowActionType,
  V1OverflowStyleType,
} from '../../__codegen__/__openapi__/search-server/fleamarket/v4/fleamarket'

export const overflowStyleEnumMapper = (style: V1OverflowStyleType) => {
  switch (style) {
    case V1OverflowStyleType.DESTRUCTIVE: {
      return OVERFLOW_STYLE.DESTRUCTIVE
    }
    case V1OverflowStyleType.NORMAL: {
      return OVERFLOW_STYLE.NORMAL
    }
    case V1OverflowStyleType.TITLE: {
      return OVERFLOW_STYLE.TITLE
    }
    case V1OverflowStyleType.MESSAGE: {
      return OVERFLOW_STYLE.MESSAGE
    }
    case V1OverflowStyleType.UNSPECIFIED:
    default: {
      return OVERFLOW_STYLE.UNKNOWN
    }
  }
}

export const overflowActionEnumMapper = (action: V1OverflowActionType) => {
  switch (action) {
    case V1OverflowActionType.DO_NOTHING: {
      return OVERFLOW_ACTION.DO_NOTHING
    }
    case V1OverflowActionType.HIDE: {
      return OVERFLOW_ACTION.HIDE
    }
    case V1OverflowActionType.HIDE_WITH_TARGET: {
      return OVERFLOW_ACTION.HIDE_WITH_TARGET
    }
    case V1OverflowActionType.POST: {
      return OVERFLOW_ACTION.POST
    }
    case V1OverflowActionType.TARGET: {
      return OVERFLOW_ACTION.TARGET
    }
    case V1OverflowActionType.UNSPECIFIED:
    default: {
      return OVERFLOW_ACTION.UNKNOWN
    }
  }
}
